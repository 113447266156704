<template>
  <v-container class="">
    <div class="my-10">
      <p>
        <span class="text-sm-h5 text-subtitle-1 font-weight-semibold"
          >Ultimos proyectos</span
        >
      </p>
      Encuentra los proyectos que mejor se adapten a tus actividades o rubros
    </div>

    <div class="container">
      <v-row>
        <v-col
          cols="12"
          xs="12"
          sm="12"
          md="12"
          lg="12"
          v-for="(project, index) in lastProjects"
          :key="index"
        >
          <v-card @click="onWorkerProjectShow(project)">
            <v-card-title class="d-flex justify-space-between">
              <span class="text-subtitle-1 font-weight-semibold primary--text">
                {{ project.name }}
              </span>
              <ProjectStatus :status="project.status" />
            </v-card-title>
            <v-card-subtitle>{{ project.description }}</v-card-subtitle>

            <!-- <v-card-subtitle>
              <div class="">
                <v-icon small>{{ icons.mdiOfficeBuilding }}</v-icon>
                {{ project.company.company }}
              </div>

              <div class="">
                <v-icon small>{{ icons.mdiMapMarker }}</v-icon>
                {{ project.city_name }} {{ project.location_name }}
              </div>
            </v-card-subtitle> -->

            <v-card-text>
              <v-chip small>{{ project.created_at }}</v-chip>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { onMounted } from "@vue/composition-api";
import TitleSection from "@/components/general/TitleSection";
import ProjectStatus from "@/components/general/projects/Status";

import { useProjects } from "@/composables/projects";
import router from "@/router";

export default {
  name: "LastProjects",
  components: {
    TitleSection,
    ProjectStatus,
  },

  setup(props) {
    const { lastProjects, onWorkerProjectShow, getLastProjects } =
      useProjects();

    onMounted(() => {
      const { limit = 10 } = router.currentRoute.query;
      getLastProjects({
        limit,
      });
    });

    return {
      lastProjects,

      onWorkerProjectShow,
    };
  },
};
</script>

<style></style>
