<template>
  <v-container class="mt-6">
    <v-row>
      <v-col :sm="fullWith ? '12' : '4'" xs="12">
        <h2>{{ title }}</h2>
        <slot name="description"></slot>
      </v-col>
      <v-col sm="8" xs="12">
        <div class="title-section-actions">
          <slot></slot>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TitleSection",
  props: {
    title: {
      type: String,
      default: null,
    },
    fullWith: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style type="scss" scoped>
.title-section-actions {
  display: flex;
  justify-content: right;
  gap: 8px;
}
</style>
